// import BaseFooter from "./components/BaseFooter";
import BaseHeader from "./components/BaseHeader/BaseHeader";

const Layout = (Component) => ({ ...Props }) => (
    <div className="page-container">
        <div><BaseHeader /></div>
        <div className="height-10"></div>
        <div className="">
            <Component {...Props} />
        </div>
        {/* <div><BaseFooter /></div> */}
    </div>
);

export default Layout;

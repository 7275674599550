import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ProfileImage from '../../assets/img/profile_image.png'
import BaseNotification from './BaseNotification'

const BaseHeader = () => {
    const location = useLocation();
    const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME
    const [isLogin, setIsLogin] = useState(true)

    useEffect(() => {
        const currentUrl = location.pathname.split('/')[1]
        const bgIncludes = ['login']
        if (bgIncludes.includes(currentUrl)) {
            setIsLogin(false)
        } else {
            setIsLogin(true)
        }
    }, [location, COMPANY_NAME])

    return (
        <header className="app-header">
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center">
                    <Link to="/">
                        <div className="app-header-start">
                            <h5 className="app-header-start-title d-md-block">{COMPANY_NAME}</h5>
                        </div>
                    </Link>
                    <div className="app-header-middle"></div>
                    <nav className="app-header-end">
                        <ul className="d-flex align-items-center">
                            {isLogin &&
                                <li className="nav-item">
                                    <a href="/login" className="nav-link btn-sign-in">
                                        <i className="fa-solid fa-right-to-bracket"></i>
                                        Sign In
                                    </a>
                                </li>
                            }
                            <li className="nav-item dropdown">
                                <a className="nav-link nav-icon" href="/" data-bs-toggle="dropdown">
                                    <i className="fa-regular fa-bell"></i>
                                    <span className="badge badge-text"><i className="fa-solid fa-circle text-warning"></i></span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notification">
                                    <BaseNotification />
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link nav-profile" href="/" data-bs-toggle="dropdown">
                                    <img src={ProfileImage} alt="Profile" />
                                </a>
                                <ul className="dropdown-menu header-profile">
                                    <li>
                                        <div className="dropdown-header bg-warning">
                                            <h6 className='dropdown-header-name'>Kevin Anderson</h6>
                                            <div className='dropdown-header-designation'>Web Designer</div>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/">
                                            <i className="fa-solid fa-user-shield"></i>
                                            <span>My Profile</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/">
                                            <div className="dropdown-item-icon">
                                                <i className="fa-regular fa-circle-question"></i>
                                            </div>
                                            <span>Need Help?</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/">
                                            <div className="dropdown-item-icon">
                                                <i className="fa-solid fa-right-from-bracket"></i>
                                            </div>
                                            <span>Sign Out</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default BaseHeader
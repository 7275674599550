import React from 'react'

const BaseNotification = () => {
    return (
        <>
            <li className="dropdown-header">
                <h6>Notifications</h6>
                <a href="/" className='app-primary-color fw-500'><i className="fa-solid fa-check-double"></i> Mark as read</a>
            </li>
            <li className="notification-item">
                <i className="fa-solid fa-circle"></i>
                <div className='w-100'>
                    <div className="notification-item-title">
                        <h6>Lorem Ipsum</h6>
                        <p>30 min. ago</p>
                    </div>
                    <p>Quae dolorem earum veritatis oditseno</p>
                </div>
            </li>
            <li className="notification-item">
                <i className="fa-solid fa-circle"></i>
                <div className='w-100'>
                    <div className="notification-item-title">
                        <h6>Atque rerum nesciunt</h6>
                        <p>1 hr. ago</p>
                    </div>
                    <p>Quae dolorem earum veritatis oditseno</p>
                </div>
            </li>
            <li className="notification-item">
                <i className="fa-solid fa-circle"></i>
                <div className='w-100'>
                    <div className="notification-item-title">
                        <h6>Sit rerum fuga</h6>
                        <p>2 hrs. ago</p>
                    </div>
                    <p>Quae dolorem earum veritatis oditseno</p>
                </div>
            </li>
            <li className="notification-item">
                <i className="fa-solid fa-circle"></i>
                <div className='w-100'>
                    <div className="notification-item-title">
                        <h6>Dicta reprehenderit</h6>
                        <p>4 hrs. ago</p>
                    </div>
                    <p>Quae dolorem earum veritatis oditseno</p>
                </div>
            </li>
            <li className="">
                <hr className='m-0 mb-1' />
            </li>
            <li className="py-1 px-2 text-center">
                <a href="/" className="fw-500"><small>View all notifications</small></a>
            </li>
        </>
    )
}

export default BaseNotification
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './layout';
import HomePage from './pages/HomePage';
import LogIn from './pages/LogIn';

const LogInComponent = Layout(LogIn)
const HomePageComponent = Layout(HomePage)

function App() {
  return (
    <div className="App">
      <>
        <Routes>
          <Route path='/' element={<HomePageComponent />}></Route>
          <Route path='/login' element={<LogInComponent />}></Route>
          <Route path='*' element={<HomePageComponent />}></Route>
        </Routes>
      </>
    </div>
  );
}

export default App;

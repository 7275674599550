import React from 'react'
const LogIn = () => {
  return (
    <div className="app-login">
      {/* <h2 className='app-login-logo'>Tech And Store</h2> */}
      <div className="card shadow-sm">
        <div className="card-header border-0 text-center bg-white">
          <h3 className='app-primary-color ont-merienda-one fw-600'>Welcome Back!</h3>
          <h6 className='mb-0'>Log back into your account</h6>
        </div>
        <div className="card-body">
          <div className="px-md-4 px-3">
            <div className="mb-3">
              <label htmlFor="username" className="form-label mb-1 fw-500">Username <span className='text-danger'>*</span></label>
              <input type="text" className="form-control fw-500" id="username" placeholder="Username" required />
            </div>
            <div className="mb-1">
              <label htmlFor="password" className="form-label mb-1 fw-500">Password <span className='text-danger'>*</span></label>
              <input type="password" className="form-control fw-500" id="password" placeholder="Password" required />
            </div>
            <div className="row px-1 mb-3">
              <div className="col-6 fw-500">
                <div className="d-flex align-items-center text-muted my-1">
                  <input type="checkbox" className="me-2" name="" id="" />
                  <small className=''>Remember Me</small>
                </div>
              </div>
              <div className="col-6 fw-500">
                <a href="/" className="d-flex align-items-center justify-content-end my-1"><small>Forget Password?</small></a>
              </div>
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-primary fw-500 w-100 login-button"><i className="fa-solid fa-right-to-bracket me-2"></i>Login!</button>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="card-footer-image">
            <img src="https://ik.imagekit.io/vnz6mv7iz/PexelsImages/pexels-sitthan-kutty-913804.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1674835829266" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogIn